<template>
  <div
    style="max-height: 500px; min-height: 500px"
    class="mt-auto flex relative"
  >
    <div
      class="
        glass-overlay
        flex
        items-center
        justify-center
        font-bold
        text-lg
        absolute
        top-0
        bottom-0
        right-0
        left-0
        z-50
      "
    >
      <p>No tracking is available at this time</p>
    </div>
    <div class="w-1/3 p-6 border overflow-y-auto slim-scrollbar">
      <span class="font-bold text-black uppercase"></span>
      <div class="mt-2 mb-4">
        <span class="text-md font-semibold text-gray-500 uppercase text-md">
          tracking history
        </span>
      </div>
      <div class="shadow rounded p-3">
        <span class="text-sm uppercase" style="color: #d4b376"
          >Lorem, ipsum.</span
        >
        <p class="text-md">Lorem, ipsum dolor.</p>
        <p class="text-gray-400">
          {{ $moment().format("DD/MM/YYYY HH:mm:ss") }}
        </p>
      </div>
      <div class="shadow rounded p-3">
        <span class="text-sm uppercase" style="color: #d4b376"
          >Lorem, ipsum.</span
        >
        <p class="text-md">Lorem, ipsum dolor.</p>
        <p class="text-gray-400">
          {{ $moment().format("DD/MM/YYYY HH:mm:ss") }}
        </p>
      </div>
      <div class="shadow rounded p-3">
        <span class="text-sm uppercase" style="color: #d4b376"
          >Lorem, ipsum.</span
        >
        <p class="text-md">Lorem, ipsum dolor.</p>
        <p class="text-gray-400">
          {{ $moment().format("DD/MM/YYYY HH:mm:ss") }}
        </p>
      </div>
      <div class="shadow rounded p-3">
        <span class="text-sm uppercase" style="color: #d4b376"
          >Lorem, ipsum.</span
        >
        <p class="text-md">Lorem, ipsum dolor.</p>
        <p class="text-gray-400">
          {{ $moment().format("DD/MM/YYYY HH:mm:ss") }}
        </p>
      </div>
      <div class="shadow rounded p-3">
        <span class="text-sm uppercase" style="color: #d4b376"
          >Lorem, ipsum.</span
        >
        <p class="text-md">Lorem, ipsum dolor.</p>
        <p class="text-gray-400">
          {{ $moment().format("DD/MM/YYYY HH:mm:ss") }}
        </p>
      </div>
      <div class="shadow rounded p-3">
        <span class="text-sm uppercase" style="color: #d4b376"
          >Lorem, ipsum.</span
        >
        <p class="text-md">Lorem, ipsum dolor.</p>
        <p class="text-gray-400">
          {{ $moment().format("DD/MM/YYYY HH:mm:ss") }}
        </p>
      </div>
      <div class="shadow rounded p-3">
        <span class="text-sm uppercase" style="color: #d4b376"
          >Lorem, ipsum.</span
        >
        <p class="text-md">Lorem, ipsum dolor.</p>
        <p class="text-gray-400">
          {{ $moment().format("DD/MM/YYYY HH:mm:ss") }}
        </p>
      </div>
      <div class="shadow rounded p-3">
        <span class="text-sm uppercase" style="color: #d4b376"
          >Lorem, ipsum.</span
        >
        <p class="text-md">Lorem, ipsum dolor.</p>
        <p class="text-gray-400">
          {{ $moment().format("DD/MM/YYYY HH:mm:ss") }}
        </p>
      </div>
    </div>
    <div
      style="background-image: url(/map.png)"
      class="
        map-container
        bg-center bg-cover bg-no-repeat bg-gray-200
        w-2/3
        mt-8
        text-xs
      "
    ></div>
  </div>
</template>

<script>
export default {
  name: "TrackingPlaceholder",
};
</script>

<style>
.glass-overlay {
  background: rgba(255, 255, 255, 0.494);
  backdrop-filter: blur(10.3px);
  -webkit-backdrop-filter: blur(10.3px);
  border-top: 1px solid rgba(240, 240, 240, 0.44);
}
</style>