<template>
  <div class="overflow-auto h-full flex flex-col">
    <div class="flex items-center font-semibold text-black text-xl px-6 my-4">
      <svg
        @click="$router.go(-1)"
        class="cursor-pointer flex items-center mr-2"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#000000"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M19 12H6M12 5l-7 7 7 7" />
      </svg>

      Order {{ order.inquiryIdString }}

      <button
        :class="orderStatusStyle"
        class="text-xs font-semibold px-2 py-1 rounded ml-4 mr-4"
      >
        {{ orderStatus }}
      </button>

      <button
        v-if="order.status === 'onHold'"
        @click="showPlaceOrderModal = true"
        style="background-color: #d4b376"
        class="ml-4 font-medium text-black text-sm px-3 py-1 rounded-md"
      >
        Place Order
      </button>
    </div>

    <PlaceOrderModal
      v-if="order.status === 'onHold'"
      v-show="showPlaceOrderModal"
      @close-modal="showPlaceOrderModal = false"
    />

    <FilesModal
      :customerDocs="order.customerDocs"
      v-show="showFilesModal"
      @close-modal="showFilesModal = false"
    />

    <div class="px-6 mt-8 uppercase text-sm font-semibold">
      <h1>Summary</h1>
    </div>
    <div class="w-full grid grid-cols-1 md:grid-cols-4 my-4">
      <div class="bg-white px-6 text-xs">
        <p class="uppercase text-gray-600 py-1">ORDER TOTAL</p>
        <p>
          {{
            order.grandTotal !== undefined && order.grandTotal !== null
              ? `$${parseFloat(order.grandTotal).toFixed(2)}`
              : "-"
          }}
        </p>
      </div>
      <div class="bg-white px-6 text-xs">
        <p class="uppercase text-gray-600 py-1">date ordered</p>
        <p>
          {{
            order.dateOrdered
              ? $moment(order.dateOrdered).format("DD MMMM YYYY")
              : "-"
          }}
        </p>
      </div>
      <div class="bg-white px-6 text-xs">
        <p class="uppercase text-gray-600 py-1">eta</p>
        <p>{{ order.eta ? $moment(order.eta).format("DD MMMM YYYY") : "-" }}</p>
      </div>
      <div class="bg-white px-6 text-xs">
        <p class="uppercase text-gray-600 py-1">last location</p>
        <p v-if="order.latestTrackingData && order.latestTrackingData.location">
          {{ order.latestTrackingData.location }}
        </p>
        <p v-else>-</p>
      </div>
    </div>

    <div class="px-6 mt-8 uppercase font-semibold flex items-center mb-4">
      <h1 class="text-md">ORDER DETAILS</h1>
      <button
        :disabled="!order.customerDocs || !order.customerDocs.length"
        :class="{
          'cursor-not-allowed':
            !order.customerDocs || !order.customerDocs.length,
        }"
        @click="
          order.customerDocs && order.customerDocs.length
            ? (showFilesModal = true)
            : null
        "
        style="background-color: #d4b376"
        class="
          px-3
          py-1
          rounded-md
          text-black
          flex
          items-center
          font-semibold
          cursor-pointer
          mr-2
          ml-4
        "
      >
        <h1 class="text-xs">DOWNLOAD FILES</h1>
        <svg
          class="ml-1"
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#000000"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path
            d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 9l-5 5-5-5M12 12.8V2.5"
          />
        </svg>
      </button>
      <pdfsvg
        :hasFiles="order.customerDocs && order.customerDocs.length"
        @click="
          order.customerDocs && order.customerDocs.length
            ? (showFilesModal = true)
            : null
        "
      ></pdfsvg>
    </div>

    <!-- TABLE START -->
    <div class="flex mb-8">
      <div class="container mx-auto px-6 ml-0">
        <div class="inq-table">
          <div>
            <div class="flex flex-col">
              <div class="flex-grow relative">
                <table class="relative w-full mr-20">
                  <thead class="w-full">
                    <tr class="text-left">
                      <th
                        class="
                          top-0
                          px-1
                          commit(mutations.SET_LOADING,
                          false);
                          font-light
                          bg-white
                          text-xs
                          hover:bg-gray-100
                          pl-2
                        "
                      >
                        #
                      </th>
                      <th
                        class="
                          top-0
                          px-1
                          py-2
                          text-gray-500
                          font-light
                          bg-white
                          text-xs
                          hover:bg-gray-100
                        "
                      >
                        PART/MODEL
                      </th>
                      <th
                        class="
                          top-0
                          px-1
                          py-2
                          text-gray-500
                          font-light
                          bg-white
                          text-xs
                          hover:bg-gray-100
                        "
                      >
                        DESCRIPTION
                      </th>
                      <th
                        class="
                          top-0
                          px-1
                          py-2
                          text-gray-500
                          font-light
                          bg-white
                          text-xs
                          hover:bg-gray-100
                        "
                      ></th>
                      <th
                        class="
                          top-0
                          px-1
                          py-2
                          text-gray-500
                          font-light
                          bg-white
                          text-xs
                          hover:bg-gray-100
                          commit(mutations.SET_LOADING,
                          false);
                          top-0
                          px-1
                          py-2
                          text-gray-500
                          font-light
                          bg-white
                          text-xs
                          hover:bg-gray-100
                        "
                      >
                        UNIT
                      </th>
                      <th
                        class="
                          top-0
                          px-1
                          py-2
                          text-gray-500
                          font-light
                          bg-white
                          text-xs
                          hover:bg-gray-100
                        "
                      >
                        QTY
                      </th>
                      <th
                        class="
                          top-0
                          px-1
                          py-2
                          text-gray-500
                          font-light
                          bg-white
                          text-xs
                          hover:bg-gray-100
                        "
                      >
                        UNIT PRICE
                      </th>
                      <th
                        class="
                          top-0
                          px-1
                          py-2
                          text-gray-500
                          font-light
                          bg-white
                          text-xs
                          hover:bg-gray-100
                        "
                      >
                        TTL PRICE
                      </th>
                    </tr>
                  </thead>

                  <tbody class="text-xs">
                    <!-- ADD INQUIRY ITEM -->

                    <tr
                      v-for="item in order.items"
                      :key="item._id"
                      class="items-center bg-white hover:bg-gray-50"
                    >
                      <td class="px-1 py-1">{{ item.itemId }}</td>
                      <td class="px-1 py-1">{{ item.modelOrPart }}</td>
                      <td class="px-1 py-1">
                        {{ item.description }}
                      </td>
                      <td class="px-1 py-1">
                        <ViewItemImageModal
                          v-if="item.showImagesModal"
                          @close-modal="item.showImagesModal = false"
                          :images="item.images"
                        ></ViewItemImageModal>
                        <svg
                          :class="{
                            'cursor-pointer': item.images && item.images.length,
                            'cursor-not-allowed opacity-20':
                              !item.images || !item.images.length,
                          }"
                          @click="
                            item.images && item.images.length
                              ? (item.showImagesModal = true)
                              : null
                          "
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#000000"
                          stroke-width="1"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <rect x="3" y="3" width="18" height="18" rx="2" />
                          <circle cx="8.5" cy="8.5" r="1.5" />
                          <path d="M20.4 14.5L16 10 4 20" />
                        </svg>
                      </td>
                      <td class="px-1 py-1">{{ item.unit }}</td>
                      <td class="px-1 py-1">{{ item.quantity }}</td>
                      <td class="px-1 py-1">{{ "$" + item.unitPrice }}</td>
                      <td class="px-1 py-1">{{ "$" + item.totalPrice }}</td>
                    </tr>
                  </tbody>
                </table>
                <div><!-- PAGINATION HERE --></div>
              </div>
            </div>
          </div>
        </div>
        <!-- TABLE END-->
      </div>
    </div>
    <div
      v-if="order.trackingData && order.trackingData.length"
      style="max-height: 600px; min-height: 600px"
      class="flex mt-auto"
    >
      <div class="w-1/3 p-6 border overflow-y-auto slim-scrollbar">
        <span class="font-bold text-black uppercase"></span>
        <div class="mt-2 mb-4">
          <span class="text-md font-semibold text-gray-500 uppercase text-md">
            tracking history
          </span>
        </div>
        <div
          v-for="update in order.trackingData"
          :key="update._id"
          class="shadow rounded p-3"
        >
          <span class="text-xs uppercase" style="color: #d4b376">{{
            update.location
          }}</span>
          <p class="text-xs">
            {{ update.description }}
          </p>
          <p class="text-gray-400 text-xs">
            {{ $moment(update.time).format("DD/MM/YYYY HH:mm:ss") }}
          </p>
        </div>
      </div>
      <div class="map-container w-2/3 text-xs">
        <GoogleMap
          v-if="order.latestTrackingData && order.latestTrackingData.latLong"
          :api-key="mapsApiKey"
          :center="mapCenter"
          :zoom="8"
        >
          <Marker :options="{ position: mapCenter }"></Marker>
        </GoogleMap>
      </div>
    </div>
    <TrackingPlaceholder v-else></TrackingPlaceholder>
  </div>
</template>

<script>
import PlaceOrderModal from "../modules/modals/PlaceOrderModal";

import FilesModal from "../modules/modals/FilesModal";
import ViewItemImageModal from "@/components/modules/modals/ViewItemImageModal";
import TrackingPlaceholder from "@/components/modules/Order/TrackingPlaceholder";

import { GoogleMap, Marker } from "vue3-google-map";

import pdfsvg from "../base/pdfsvg";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Order",
  data() {
    return {
      mapsApiKey: process.env.VUE_APP_MAPS_KEY,
      showPlaceOrderModal: false,
      showImageModal: false,
      showFilesModal: false,
    };
  },
  methods: {
    ...mapActions(["getSingleOrder"]),
  },
  computed: {
    ...mapGetters({ order: "selectedOrder" }),

    mapCenter() {
      if (
        this.order.latestTrackingData &&
        this.order.latestTrackingData.latLong
      ) {
        return JSON.parse(this.order.latestTrackingData.latLong);
      } else {
        return null;
      }
    },

    orderStatus() {
      switch (this.order.status) {
        case "pricing":
          return "Pricing";
        case "onHold":
          return "On Hold";
        case "rejected":
          return "Rejected";
        case "approved":
          return "Approved";
        case "completed":
          return "Completed";
        default:
          return "None";
      }
    },
    orderStatusStyle() {
      switch (this.order.status) {
        case "pricing":
          return {
            "bg-yellow-50 text-yellow-600": true,
          };
        case "completed":
          return {
            "bg-green-50 text-green-400": true,
          };
        case "onHold":
          return {
            "bg-blue-50 text-blue-400": true,
          };
        case "approved":
          return {
            "bg-blue-50 text-green-400": true,
          };
        case "rejected":
          return {
            "bg-red-50 text-red-400": true,
          };
        default:
          return {};
      }
    },
  },
  mounted() {
    this.getSingleOrder(this.$route.params.id);
  },

  components: {
    pdfsvg,
    PlaceOrderModal,
    FilesModal,
    ViewItemImageModal,
    TrackingPlaceholder,
    GoogleMap,
    Marker,
  },
};
</script>


<style scoped>
.map-container div {
  height: 100%;
}

.inq-table {
  th {
    position: sticky;
    z-index: 48;
  }
}

ul li.liborder:hover,
ul li.liborder:active {
  border-bottom: solid 4px;
  border-color: #000;
}
</style>