<template>
  <div class="modal-overlay" @click="$emit('close-modal')">
    <div class="modal" @click.stop>
      <img class="check" src=" " alt="" />
      <h6>Place Your Order</h6>

      <div
        ref="dragDrop"
        class="
          items-center
          text-center
          border
          rounded
          border-gray-400
          hover:border-black
          border-dashed
          p-6
          m-6
          px-20
        "
        @dragover.prevent="handleDragOver"
        @dragleave.prevent="handleDragLeave"
        @drop="filesSelected"
      >
        <div class="text-black text-md pb-1">Upload P.O Here</div>

        <div class="text-gray-400 text-xs pb-3">
          Files supported: jpg, png, jpeg, pdf
        </div>

        <label
          class="
            cursor-pointer
            px-2
            py-1
            inline-block
            bg-black
            text-white text-sm
            rounded
          "
        >
          <input
            @change="filesSelected"
            type="file"
            id=""
            single
            class="hidden"
          />
          Choose Files
        </label>
      </div>

      <p v-if="file">Selected File</p>

      <p v-if="file">{{ file.name }}</p>

      <button
        @click="
          placeOrder({ file, id: $route.params.id }), $emit('close-modal')
        "
        :disabled="!file"
        :class="{ 'cursor-not-allowed': !file }"
      >
        Place Order
      </button>
    </div>
    <div class="close" @click="$emit('close-modal')">
      <img class="close-img" src="/closeicon.png" alt="" />
    </div>
  </div>
</template>
 



<script>
import { mapActions } from "vuex";

export default {
  name: "PlaceOrderModal",

  data() {
    return {
      file: null,
    };
  },
  methods: {
    ...mapActions(["placeOrder"]),
    handleDragOver() {
      this.$refs.dragDrop.style.background = "#eee";
    },
    handleDragLeave() {
      this.$refs.dragDrop.style.background = "";
    },

    filesSelected(e) {
      e.preventDefault();
      console.log(e);
      this.$refs.dragDrop.style.background = "";

      if (
        e.dataTransfer &&
        e.dataTransfer.files &&
        e.dataTransfer.files.length
      ) {
        this.file = e.dataTransfer.files[0];
      }

      if (e.target && e.target.files && e.target.files.length) {
        this.file = e.target.files[0];
        e.target.value = null;
      }
    },
  },

  components: {},
};
</script>


<style scoped>
.modal-overlay {
  position: fixed;
  z-index: 10001;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.modal {
  z-index: 10002;
  text-align: center;
  background-color: white;
  height: 500px;
  width: 500px;
  margin-top: 10%;
  padding: 60px 0;
  border-radius: 20px;
}
.close {
  margin: 10% 0 0 16px;
  cursor: pointer;
}

.close-img {
  width: 25px;
}

.check {
  width: 150px;
}

h6 {
  font-weight: 500;
  font-size: 28px;
  margin: 20px 0;
}

p {
  font-size: 16px;
  margin: 20px 0;
}

button {
  background-color: #d4b376;
  width: 150px;
  height: 40px;
  color: black;
  font-size: 14px;
  border-radius: 6px;
  margin-top: 50px;
}

button:hover {
  background-color: #251a05;
  width: 150px;
  height: 40px;
  color: white;
  font-size: 14px;
  border-radius: 6px;
  margin-top: 50px;
}
</style>

 