<template>
  <div class="modal-overlay" @click="$emit('close-modal')"></div>
  <!-- Image View Modal -->
  <div
    :style="{ 'background-image': backgroundImage }"
    style="background-size: cover"
    class="modal relative"
  >
    <!-- RIght Arrow -->
    <span
      v-if="currentImageIndex + 1 < images.length"
      @click="currentImageIndex++"
      style="right: 0; top: 50%; transform: translate(50%, -50%)"
      class="
        cursor-pointer
        absolute
        w-10
        h-10
        bg-black
        rounded-full
        flex
        justify-center
        items-center
        opacity-60
      "
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#ffffff"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M9 18l6-6-6-6" /></svg
    ></span>
    <!-- / RIght Arrow -->
    <!-- Left Arrow -->
    <span
      v-if="currentImageIndex - 1 >= 0"
      @click="currentImageIndex--"
      style="left: 0; top: 50%; transform: translate(-50%, -50%)"
      class="
        cursor-pointer
        absolute
        w-10
        h-10
        bg-black
        rounded-full
        flex
        justify-center
        items-center
        opacity-60
      "
      ><svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#ffffff"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      >
        <path d="M15 18l-6-6 6-6" /></svg
    ></span>
    <!-- / Left Arrow -->
    <div class="close flex">
      <span @click="$emit('close-modal')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#fff"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="15" y1="9" x2="9" y2="15"></line>
          <line x1="9" y1="9" x2="15" y2="15"></line>
        </svg>
      </span>
    </div>
  </div>
  <!-- / Image View Modal -->
</template>
 



<script>
export default {
  name: "ViewItemImageModal",
  emits: ["close-modal"],
  props: ["images"],
  data() {
    return {
      currentImageIndex: 0,
      files: [],
    };
  },
  methods: {},
  computed: {
    imageUrls() {
      if (!this.images) return [];
      return this.images.map((image) => {
        return `${process.env.VUE_APP_API_HOST}/uploads/${image}`;
      });
    },
    backgroundImage() {
      if (this.imageUrls && this.imageUrls.length)
        return `linear-gradient(180deg, rgba(0,0,0,.5) 0%, rgba(255,255,255,0) 25%), url(${
          this.imageUrls[this.currentImageIndex]
        }) `;
      else
        return "linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 25%)";
    },
  },
  mounted() {
    if (!this.hasFiles) {
      this.showImageUploadModal = true;
    }
  },
};
</script>


<style scoped>
.modal-overlay {
  position: fixed;
  z-index: 10001;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  background-color: #000000da;
}

.modal-content {
  padding: 0 30px 30px 30px;
  overflow-y: auto;
  height: 90%;
}

.modal-content::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border: 4px solid transparent;
  border-radius: 8px;
  background-clip: padding-box;
}

.modal-content::-webkit-scrollbar {
  width: 16px;
}

.modal {
  z-index: 10002;
  text-align: center;
  background-color: white;
  height: 520px;
  width: 620px;
  padding: 60px 0 0 0;
  border-radius: 20px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.close {
  margin: 2% 0 0 16px;
  cursor: pointer;
  position: absolute;
  right: 8px;
  top: 0;
}

.close-img {
  width: 25px;
}

.check {
  width: 150px;
}

h6 {
  font-weight: 500;
  font-size: 28px;
  margin: 20px 0;
}

p {
  font-size: 16px;
  margin: 20px 0;
}

.button {
  background-color: #d4b376;
  width: 150px;
  height: 40px;
  color: black;
  font-size: 14px;
  border-radius: 6px;
  margin-top: 50px;
}

.button:hover {
  background-color: #251a05;
  width: 150px;
  height: 40px;
  color: white;
  font-size: 14px;
  border-radius: 6px;
  margin-top: 50px;
}
</style>

 